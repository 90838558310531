import Image from "next/image";
import { staticPrefix } from "@/core/utils/media/static";

// Use the prop types from the existing Image component
type AppImageProps = React.ComponentProps<typeof Image>;

const AppImage: React.FC<AppImageProps> = ({ src, ...props }) => {
  return <Image src={staticPrefix(src)} {...props} />; // eslint-disable-line jsx-a11y/alt-text
};

export default AppImage;
