import { Link } from "@/components/generic/navigation/Link";
import AppImage from "@/components/generic/ui/AppImage";

export const Error404: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center m-auto w-full max-w-xl text-center p-6">
      <AppImage
        src="/static/global/images/error-404.webp"
        alt="Eroare 404"
        width={647}
        height={651}
        priority
        unoptimized
        className="mb-6 w-full max-w-[300px]"
      />
      <span className="mb-3 rounded-lg bg-error-100 text-error-800 font-semibold text-sm px-2 py-1">Eroare 404</span>
      <h1 className="mb-2">Pagina nu a fost găsită</h1>
      <p className="md:text-lg mb-6">Pagina curentă nu există sau a fost mutată.</p>
      <div className="flex items-center justify-center">
        <Link href="/" className="fake-button large">
          Mergi la prima pagină
        </Link>
      </div>
    </div>
  );
};
